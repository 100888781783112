.addSystemMessageRTE {
    padding: 2px;
    overflow: hidden;
    min-height: 40vh !important;
    background-color: white !important;
    position: relative;
    z-index: 1;
}

.addSystemMessageRTE .slate-HeadingToolbar {
    top: 0px;
    position: sticky;
    padding-bottom: 3px;
    margin-bottom: 3px;
}

.addSystemMessageRTE div[role=textbox] {
    max-height: 26vh;
    overflow-y: scroll;
}


.addSystemMessageRTE div[class*="ToolbarDropdown___StyledDi"] {
    z-index: 20000 !important
}