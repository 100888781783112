.model {
  display: flex;
}

.modelTab {
  width: 50%;
  height: 454px;
  border: 0;
  border-radius: 4px;
  padding: 32px;
  background-color: #ffffff;
}


.tab-bord {
  border: 1px solid rgb(112, 112, 112, 0.5);

  border-radius: 0px 16px;
}

.bottom-border {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}


.content-scroll {
  overflow-y: overlay;
  max-height: 250px;
}


.modelRow {
  flex-basis: 100%;
  display: flex;
  min-width: 0;
  border-top: 1px solid rgb(112, 112, 112, 0.5);
}

.modelPill {
  max-width: 20%;
  min-width: 20%;
  height: 60px;
  flex-basis: 25%;
  padding: 20px 5px 10px 0px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Bold";
  color: #333333;
  text-transform: uppercase;
  opacity: 1;
}

.last-col {
  border-right: 0 !important;
}


.studyTypeHeader {
  max-width: 20%;
  min-width: 20%;
  height: 60px;
  flex-basis: 25%;
  padding: 16px 5px 10px 0;
  border-right: 1px solid rgb(112, 112, 112, 0.5);
}

.studyType {
  max-width: 20%;
  min-width: 20%;
  height: 60px;
  flex-basis: 25%;
  padding: 20px 5px 10px 24px;
  border-right: 1px solid rgb(112, 112, 112, 0.5);
  background: #fff;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.excel-download {
  cursor: pointer;
  color: #000;
  padding: 0;
}

.excel-download .disabled {
  color: #ddd;
}

.svg-loader {
  height: 2.75vmin;
  padding: 0 0 0 0;
}

.modelRow a.badge.nav-link.study-data-pill.nav-link.active {
  background: #0645AD !important;
  color: #ffffff;
  border-radius: 24px !important;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Demi";
}

.modelRow a.badge.nav-link.study-data-pill.nav-link {
  color: #0645AD;
  border-radius: 24px !important;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px !important;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Demi";
}

.modelRow a.badge.nav-link.study-data-pill.nav-link:hover {
  background-color: rgba(255, 255, 255, 0);
}

.tab-bord ::-webkit-scrollbar {
  height: 0;
  width: 18px;
}

.tab-bord ::-webkit-scrollbar-track {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-left: 0.5px solid #DFDFDF;
  border-radius: 1px 4px 1px 1px;
  opacity: 1;
}

.tab-bord ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #C3C3C3;
  border: 5px solid #FAFAFA;
}

.AvenirNextLTProMedium {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium" !important;
}

p.study-details-model-summary-card-title {
  font-size: 24px;
  font-weight: 400;
  padding-left: 16px;
  color: #056A7B;
  margin-bottom: 40px;
}

.downloadAll {
  min-width: 142px;
}

.studyType.model-tab-download-col {
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 20px;
  text-align: center;
}

.model-tab-download-cell {
  margin: 0px;
  padding: 0px;
}

.studyType {
  padding-left: 20px;
  font-size: 14px;
}

.studyDetails-modelTab-dlColHeader.studyDetails-modelTab-dlColHeader {
  font-size: 12px;
  color: #0645AD;
  text-align: center;
  text-transform: capitalize;
  padding-right: 40px;
}

.studyDetails-modelTab-dlColHeader-tab {
  padding-right: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  font-family: "AvenirNextLTPro-Bold", sans-serif;
  color: #333333;
  text-transform: uppercase;
  opacity: 1;
}

.modelPill.last-col.downloadAll {
  padding-left: 5px;
}

.headerBackground {
  background: #2222221A;
}

.model.headerBackground {
  border-radius: 0px 16px 0px 0px;
}

.modelTab-downloadDisabledLink:hover {
  background-color: #fff;
}

.modelTab-downloadDisabledLink {
  margin-left: 0px;
}

.model-tab-download-cell:hover {
  background-color: #fff;
}

@-moz-document url-prefix() {

  html,
  body,
  div {
    scrollbar-width: thin;
  }

  .content-scroll {
    overflow-y: auto;
    max-height: 250px;
  }
}