.edit-help-container {
  margin-top: 25px;
}

.edit-help-container .heading {
  float: left;
}

.edit-help-container .back button {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  border: 0px;
}
