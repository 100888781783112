h2.MuiDialogTitle-root {
    font-size: 20px;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px 24px;
}
    
h2.MuiDialogTitle-root > div {
    margin-left: auto;
}

.MuiDialog-paperScrollPaper {
    border-radius: 0px 16px !important;
    width: 95%;
    max-width: unset !important;
}

div#popupHeader {
    background: #002334 0% 0% no-repeat padding-box;
}

.study-data-filters-header-popup {
    border: none;
    padding-left: 24px;
    padding-top: 15px;
    padding-bottom: 20px;
}
    
/* Custom styling for the scroll bar */
.MuiDialogContent-root::-webkit-scrollbar-thumb {
    width: 0px;
    border-right: 23px white solid;
    border-radius: 0px;
}

.MuiDialogContent-root::-webkit-scrollbar {
    width: 32px;
}

.MuiDialogContent-root::-webkit-scrollbar-track {
    width: 10px;
    background: transparent;
}
/* Custom styling for the scroll bar ENDS */