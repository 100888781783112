
.previewBtn {
  width: 165px;
  background-color: darkblue;
  margin: 10px 0px 5px 20px;
}

.flexSpacer {
  flex-grow: 5;
}

.updateText {
  text-align: right;
  padding-right: 15px;
  align-self: center;
}

.study-summary-card {
  background: #ffffff;
  padding: 32px;
  border-radius: 0px 16px 0px 16px;
  box-shadow: 0px 0px 12px #0000000A;
  margin-bottom: 32px;
}

.d-flex {
  display: flex;
}

.ln-spacing {
  padding-bottom: 40px;
}


nav.border-none.data-rb-event-tab.nav.card-header-tabs.nav-tabs a.active {
  color: #222222;
  font-size: 16px;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: var(--orange);
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
}

nav.border-none.data-rb-event-tab.nav.card-header-tabs.nav-tabs a {
  color: #777777;
  font-size: 16px;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: #777777;
  margin: 0;
  padding-right: 12px !important;
  padding-left: 0px !important;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
}

nav.border-none.data-rb-event-tab.nav.card-header-tabs.nav-tabs a:hover {
  background: transparent;
  border: 1px solid #ffffff;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
}

nav.border-none.data-rb-event-tab.nav.card-header-tabs.nav-tabs {
  margin-left: 0px;
  margin-bottom: 32px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
}

.card-header-pills.mt-2.nav.card-header-pills.nav-pills {
  background-color: #F0F5FC;
  margin-right: 32px;
  margin-left: 32px;
  margin-top: 40px;
  border-radius: 4px;
  box-shadow: 0px 0px 12px #0000000a;
}

a.badge.nav-link.study-data-pill.nav-link.active {
  background: #0645AD !important;
  color: #ffffff;
  border-radius: 24px !important;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
}

a.badge.nav-link.study-data-pill.nav-link {
  color: #0645AD;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
}

a.badge.nav-link.study-data-pill.nav-link:hover {
  background-color: rgba(255, 255, 255, 0);
}

span.switch-selection.switch-individual {
  display: none;
}

span.switch-selection.switch-summary {
  height: 38px;
}

.switch-selection {
  position: absolute;
  z-index: 1;
  left: 107px;
  top: -2px;
  display: block;
  width: 135px;
  height: 34px;
  border-radius: 24px;
  background-color: var(--orange);
  transition: left 0.15s ease-out;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -ms-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}

.form-check-input:checked+.label-summary~.switch-selection {
  left: -1.5px;
}

.form-check {
  position: relative;
  height: 34px;
  width: 85px;
  margin: 20px auto;
  border-radius: 24px;
}

.form-check-label {
  position: relative;
  z-index: 2;
  float: left;
  width: 84px;
  line-height: 34px;
  font-size: 16px;
  color: #000;
  text-align: center;
  cursor: pointer;
  border-radius: 24px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
}

.form-check-input {
  display: none;
}

.form-check-input:checked+.form-check-label {
  color: #ffffff;
}

.button-wrapper {
  border: 2px solid var(--orange);
  width: 269px;
  height: 39px;
  border-radius: 25px;
}

.form-check-inline {
  display: inline-flex;

  align-items: center;
  padding-left: 0;
  margin: 0px !important;
}

.label-individual {
  left: 75px;
}

.label-summary {
  left: 24px;
}

.form-check-label .form-check-input:checked~span {
  color: #FFFFFF;
}

.form-check-label .form-check-input:checked~.switch-selection {
  left: -25px;
  z-index: -1;
}

.details-inner-container-mphs {
  background-color: #F6F6F6;
  padding: 16px 64px 40px 64px;
}

.landing-inner-container-mphs {
  top: 64px;
  padding: 0 64px;
}

.card-no-border {
  border: none;
  height: 100%;
}

.MuiTypography-root.card-head-study {
  padding-left: 16px;
  font-size: 24px;
  font-weight: 400;
  color: #056A7B;
}

.bcStudyDoc {
  padding-left: 16px !important;
  font-size: 24px !important;
  font-weight: 400;
  color: "#056A7B"
}

.AvenirNextLTProMedium {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium" !important;
}

.MuiCardContent-root.card-mid-lt {
  padding: 0;
}

.MuiCardContent-root.card-mid-rt {
  padding: 0 !important;
}

.MuiCardContent-root.card-bel-lt {
  padding: 0;
}

.MuiCardContent-root.card-bel-rt {
  padding: 0 !important;
}

.AvenirNextLTProDemi {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Demi" !important;
}

.AvenirNextLTProRegular {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular" !important;
}

.MuiTypography-root.card-head-sub {
  font-size: 20px;
  font-weight: 400;
  color: #002334;
}

.div-sep-study {
  height: 1px;
  border-color: #D2D2D2;
  opacity: 1;
}

.pad-tp-sub-head {
  padding-top: 36px;
}

.study-summ-content-hd {
  font-size: 14px !important;
  color: #222222 !important;
}

.study-summ-content {
  font-size: 16px !important;
  color: #222222 !important;
}

.legend-list {
  height: 350px;
  overflow: scroll;
}

* {
  box-sizing: border-box;
}

.leftcolumn {
  float: left;
  width: 50%;
}

.rightcolumn {
  float: left;
  width: 50%;
}

.card-study-sum {
  background-color: #F6F6F6;
}

.card-study-doc {
  background-color: #F6F6F6;
  margin-bottom: 32px;
  margin-left: 15px;
  margin-right: 15px;
}

.study-details-model-summary-card-inner {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0px 16px;
  padding: 32px;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px #0000000A;
}

.card-study-mod {
  background-color: #F6F6F6;
}

.studyDetails-cardMain .study-data-card-header {
  border: none;
  padding-bottom: 40px;
  border-radius: 0px 16px;
}

.study-data-nav-pills-band {
  margin-top: 0px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 32px !important;
  margin-bottom: 32px;
}

.study-data-nav-pills-band-disabled {
  background-color: lightgrey !important;
  cursor: not-allowed !important;
}

.study-data-nav-pills-band-disabled>.nav-item {
  cursor: not-allowed !important;
}

.disable-nav-item {
  cursor: not-allowed !important;
}

.study-data-nav-pills-band .disable-nav-item {
  background-color: lightgrey;
  padding-right: 8px;
  margin-left: 5px;
}

.disable-nav-item .nav-link {
  color: grey !important;
}

.study-details-data-filters-launcher-button {
  margin-left: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
  background: #FFFFFF;
  border: 1px solid var(--orange);
  border-radius: 4px;
  color: #222222;
  font-size: 16px;
  text-transform: uppercase;
  padding: 8px 16px;
  width: auto;
}

.button-font>b {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
}

.study-details-data-filters-launcher-button:hover {
  color: #222222;
  background: var(--orange);
  border: 1px solid #FFFFFF;
}

.study-details-data-filters-launcher-button:focus {
  color: #222222;
  background: var(--orange);
  border: 1px solid #FFFFFF;
}

.study-details-data-filters-launcher-button.disabled,
.study-details-data-filters-launcher-button:disabled {
  color: #222222;
  background: #FFFFFF;
  border: 1px solid var(--orange);
}

.study-details-study-data-card-body {
  padding-left: 32px;
  padding-top: 32px;
}

.study-details-study-data-nav-link {
  margin: 0;
  padding: 0;
}

.previewBtnContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.study-details-data-filters {
  margin-left: 32px;
  padding-left: 0;
  background: #FBFBFB;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  max-width: -webkit-fill-available;
  padding-right: 0px;
  margin-right: 32px;
}

@supports (-moz-appearance:none) {
  .study-details-data-filters {
    max-width: calc(100% - 64px);
  }
}


.study-data-filters-header {
  border: none;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 40px;
}

.card-data-filters.card-data-filters {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
  font-size: 24px;
  color: #222222DE;
}

.studyDetails-dataFilters-cardBody {
  padding-top: 0px;
  padding-left: 24px;
}

.studyDetails-dataFilters-formLabel {
  font-size: 16px;
  margin-bottom: 12px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular" !important;
}

.studyDetails-dataFilters-formGroup {
  margin-bottom: 32px;
}

.modelDataBtnContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}

.modelDataCloseBtnRow {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  color: #FFFFFF;
  font-size: 16px;
  margin: 1rem 0;
  height: 200px;
}

.file-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.modelDataError {
  color: #FF0000;
  font-size: 16px;
  margin-top: 1rem;
}

.file-info:hover {
  cursor: pointer;
}

.modelDataCloseBtnContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem 0 1rem;
}

.modelDataBackNextBtn {
  background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
  color: #FFFFFF;
  width: 40%;
  font-size: 16px;
  margin-top: 1.2rem;
}

.modelDataRow {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  color: #FFFFFF;
  font-size: 16px;
  margin: 1rem 0 1rem 0;
}

.modelDataBtn {
  text-decoration: underline;
  cursor: pointer;
  color: #FFFFFF;
  border: none;
  background: none;
  padding: 0;
}

.studyDetails-dataFilters-formGroupBottom {
  margin-bottom: 40px;
}

.studyDetails-dataFilters-formConfirmButton {
  margin-top: 0px;
  margin-bottom: 0px;
  background: var(--orange) 0% 0% no-repeat padding-box;
  border: 1px solid var(--orange);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 16px;
  text-transform: uppercase;
  width: auto;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
  margin-right: 20px;
}

.studyDetails-dataFilters-formConfirmButton:hover {
  color: #222222;
  background: var(--orange);
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
  border: 1px solid var(--orange);
}

.studyDetails-dataFilters-formConfirmButton:focus {
  color: #222222;
  background: var(--orange);
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
  border: 1px solid var(--orange);
}

.studyDetails-dataFilters-formConfirmButton:active {
  color: #222222;
  background: var(--orange);
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
  border: 1px solid var(--orange);
}

.studyDetails-dataFilters-formConfirmButton.disabled,
.studyDetails-dataFilters-formConfirmButton:disabled {
  color: #222222;
  background: #FFFFFF;
  border: 1px solid var(--orange);
}

.studyDetails-dataFilters-formCancelButton {
  margin-top: 0px;
  margin-bottom: 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--orange);
  border-radius: 4px;
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
  width: auto;
}

.studyDetails-dataFilters-formCancelButton:hover {
  color: #222222;
  background: var(--orange);
}

.studyDetails-dataFilters-formCancelButton:focus {
  color: #222222;
  background: var(--orange);
}

.studyDetails-dataFilters-formCancelButton:active {
  color: #222222;
  background: var(--orange);
}

.MuiCardContent-root.studyDetails-studySummary-projectInfoCardRight {
  padding-right: 50px !important;
}

.studyDetails-legendsCardHeader {
  background: #f7f7f7;
}

.studyDetails-studySummary-textTrim {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.studyDetails-studySummary-textTrim1Line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.studyDetails-cardComments {
  padding-top: 32px;
}

.typeahead-position-fix {
  display: block
}

.typeahead-position-fix>div.dropdown-menu.show {
  transform: translate3d(0px, 38px, 0px) !important;
}

p.MuiTypography-root.MuiTypography-body1.bcStudyDoc {
  color: #056A7B;
}

.studyDetails-cardMain {
  border: 0px;
  box-shadow: 0px 0px 12px #0000000A;
  border-radius: 0px 16px;
}

.legend-list-card {
  border-radius: 0px 16px;
  border: 1px solid #70707080;
}

.studyDetails-dataIndicatorchipLabel {
  padding-top: 5px;
}

.studyDetails-twoColTooptipTitle {
  padding: 16px;
  padding-bottom: 4px;
  padding-top: 8px;
  color: #B9283F;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Demi";
  font-size: 12px;
}

.studyDetails-twoColTooptipBase {
  display: flex;
}

.studyDetails-twoColTooptipBase-Row {
  width: 100%;
  display: flex;
  align-items: stretch;
}

.studyDetails-twoColTooptipBase-leftCol {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Demi";
  padding: 16px 16px 8px 16px;
  padding-top: 4px;
  flex-shrink: 0;
  float: left;
  width: 30%;
  min-width: 150px;
}

.studyDetails-twoColTooptipBase-rightCol {
  background: #FFFFFF;
  padding: 16px 16px 8px 16px;
  padding-top: 4px;
  flex-shrink: 0;
  float: right;
  width: 70%
}

.studyDetails-twoColTooptipBase-leftColCotent {
  margin-bottom: 0px;
  font-size: 12px
}

.studyDetails-twoColTooptipBase-contentLastRow {
  margin-bottom: 0px;
}

.addAccount-formLabel {
  font-size: 16px;
  margin-bottom: 12px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular", sans-serif !important;
  color: #FFFFFF;
}

.study-summary-button {
  color: #056A7B;
  background-color: #FFFFFF;
  border: 2px solid #056A7B;
  border-radius: 0 10px 0 10px;
  margin-right: 12px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium", sans-serif !important;
  transition: background-color 0.3s, color 0.3s;
}

.study-summary-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45vw;
  max-height: 75vh;
  overflow: auto;
  background-color: white;
  padding: 0px;
  border: 1px solid #056A7B;
  box-sizing: border-box;
}

.study-summary-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.loading-container {
  float: left;
  color: #222222;
  background-color: #ffffff;
  border: 1px solid var(--orange);
  border-radius: 3px;
  margin-right: 12px;
  /* eslint-disable-next-line */
  font-family: 'AvenirNextLTPro-Medium', sans-serif;
}

.loading-container:hover {
  background-color: #f9a868;
  border-radius: 4px;
  border: 1px solid transparent;
  /* eslint-disable-next-line */
  font-family: 'AvenirNextLTPro-Medium', sans-serif;
}

.popup-tile-btn  {
  transition-duration: .2s;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
}
.popup-tile-btn:hover {
  background-color: var(--teal);
  color: white;
}
.popup-tile-btn.active {
  background-color: var(--teal);
  color: white;
}
.popup-tile-btn svg {
  width: 25px;
  height: 25px;
  fill:  var(--teal)
}
.popup-tile-btn:hover svg {
  fill: white;
}
.popup-tile-btn.active svg {
  fill: white;
}
.popup-tile-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.popup-tile-btn:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}
