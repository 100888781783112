.inputField {
    display: block;
    margin-top: 32px;
    margin-bottom: 16px;
}

.inputFieldOTP {
    display: block;
    margin-top: 18px;
}

.inputFieldFlex {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.rememberDevice {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 32px;
}

.rememberDevice label {
    margin-bottom: 0;
}

.form {
    text-align: left;
}

.errorText {
    font-weight: 600;
    color: darkred;
}

.successText {
    font-weight: 600;
    color: rgb(32, 112, 32);
}

.authContainer {
    justify-content: center;
    height: 90vh;
    display: flex;
    align-items: center;
    background: url('bg-Login.png') no-repeat;
    background-size: 100% 100%;
    background-position: center;
    flex-direction: column;
}

.loginPage-imageLogo {
    margin-bottom: 40px;
}

.loginPage-loginFormInnerContainer {
    justify-content: left;
    text-align: left;
    background: #002334;
    border: 1px solid #707070;
    border-radius: 0px 24px;
    padding: 32px;
    width: 465px;
}

.containerTitle {
    font-size: 32px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #fff;
}

.forgotPasswordLabel {
    font-size: 16px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFFDE;
    margin-top: 16px;
    display: inline-block;
}

.forgotPasswordLabelSmall {
    font-size: 14px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFFDE;
    margin-top: 8px;
}

.forgotPasswordButton {
    font-size: 14px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFFDE;
    padding: 0 0 0 0;
    border: 0px;
    text-decoration: underline;
}

.forgotPasswordButton:hover {
    font-size: 14px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFFDE;
    text-decoration: underline;
}

.LoginFormSignInButton {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
    text-transform: uppercase;
    border: none;
}

.LoginFormSignInButton:hover {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
    text-transform: uppercase;
    border: none;
}

label.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-root.MuiFormLabel-colorPrimary.inputLabel {
    font-size: 16px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #fff;
    margin-bottom: 8px;
}

div.MuiInputBase-root.MuiInputBase-colorPrimary {
    margin-right: 0px !important;
}

.LoginFormOutlineButton {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    border: 1px solid #F8A131;
    border-radius: 4px;
    color: #FFFFFFDE;
    text-transform: uppercase;
    background: none;
    box-shadow: none;
}

.LoginFormOutlineButton:hover {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    border: 1px solid #F8A131;
    border-radius: 4px;
    color: #FFFFFFDE;
    text-transform: uppercase;
    background: none;
    box-shadow: none;
}

.LoginFormOutlineButton:not(:disabled):not(.disabled):active:focus,
.LoginFormOutlineButton:not(:disabled):not(.disabled):active,
.LoginFormOutlineButton:focus {
    border: 1px solid #F8A131;
    border-radius: 4px;
    color: #FFFFFFDE;
    background: none;
    box-shadow: none;
}

.containerSubTitle {
    font-size: 14px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFFDE;
    margin-top: 8px;
}

p.MuiTypography-root.LoginFormRememberCheckboxLabel {
    font-size: 14px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFFDE;
    margin-top: 8px;
}

.LoginForm-SuccessMessage {
    display: flex;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    margin-top: 12px;
    margin-bottom: 12px;
    background: #E9FFF3;
    border: 2px solid #007E33;
    border-radius: 0px 8px;
    vertical-align: middle;
}

.LoginForm-ErrorMessage {
    display: flex;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    margin-top: 12px;
    margin-bottom: 12px;
    background: #FFF5F3;
    border: 2px solid #B9283F;
    border-radius: 0px 8px;
    vertical-align: middle;
}

.LoginForm-ErrorMessageText,
.LoginForm-SuccessMessageText {
    padding-top: 8px;
}

.LoginForm-ErrorIcon,
.LoginForm-SuccessIcon {
    padding: 6px 8px;
}

.otpcontainerSubTitle {
    margin-top: 12px;
}

button.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root.Mui-disabled.LoginFormSignInButton {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
    text-transform: uppercase;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFF;
}

button.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root.Mui-disabled.LoginFormSignInButton:hover {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
    text-transform: uppercase;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFF;
}

.button.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root:not(:disabled):not(.disabled):active:focus,
.button.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root:not(:disabled):not(.disabled):active,
.button.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root:focus {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
    text-transform: uppercase;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFF;
}

.LoginForm-ErrorMessageText {
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 8px;
}

button.MuiButton-root.MuiButton-light.MuiButton-lightPrimary.MuiButton-sizeMedium.MuiButton-lightSizeMedium.MuiButtonBase-root.LoginFormOutlineButton {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    border: 1px solid #F8A131;
    border-radius: 4px;
    color: #FFFFFFDE;
    text-transform: uppercase;
    background: none;
    box-shadow: none;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
}

button.MuiButton-root.MuiButton-light.MuiButton-lightPrimary.MuiButton-sizeMedium.MuiButton-lightSizeMedium.MuiButtonBase-root.LoginFormOutlineButton:hover {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    border: 1px solid #F8A131;
    border-radius: 4px;
    color: #FFFFFFDE;
    text-transform: uppercase;
    background: none;
    box-shadow: none;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
}

.LoginFormSignInButtonChangePassword {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
    text-transform: uppercase;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFF;
}

.LoginFormSignInButtonChangePassword:hover {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
    text-transform: uppercase;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFF;
}

button.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root.LoginFormSignInButtonChangePassword {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
    text-transform: uppercase;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFF;
}

button.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root.Mui-disabled.LoginFormSignInButtonChangePassword {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
    text-transform: uppercase;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFF;
}

button.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root.Mui-disabled.LoginFormSignInButtonChangePassword:hover {
    font-size: 16px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #fff;
    margin-top: 32px;
}

.containerSubTitleTimeoutTimer {
    font-size: 24px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Bold';
    color: #FFFFFF;
    margin-top: 32px;
    text-align: center;
    border: 1px solid #FFFFFF;
    padding-top: 5px;
}

.containerSubTitleTimeout {
    font-size: 16px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFF;
    margin-top: 32px;
}

button.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root.LoginFormSignInButtonSessionTimeout {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    background: linear-gradient(90deg, rgb(255, 196, 85) 30%, rgb(212, 114, 1));
    text-transform: uppercase;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    color: #FFFFFF;
}

.LoginFormOutlineButtonResend {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    border: 1px solid #01697C;
    border-radius: 4px;
    background: #01697C;
    color: #FFFFFFDE;
    text-transform: uppercase;
    box-shadow: none;
}

.LoginFormOutlineButtonResend:active,
.LoginFormOutlineButtonResend:focus,
.LoginFormOutlineButtonResend:hover {
    width: 400px;
    height: 38px;
    margin-top: 32px;
    border: 1px solid #01697C;
    border-radius: 4px;
    background: #01697C;
    color: #FFFFFFDE;
    text-transform: uppercase;
    box-shadow: none;
}