.b-list-item.b-active:not(.b-disabled),
.b-list-item:focus:not(.b-disabled) {
  background-color: #EEF4FC;
  color: #4f5964;
}

.b-stripe .b-grid-row.b-selected {
  background-color: #FBFBFB40 !important;
}

.b-stripe .b-grid-row.b-selected .b-grid-cell.b-selected {
  background-color: #FBFBFB40 !important;
}

.b-stripe:focus .b-grid-row.b-selected {
  background-color: #FBFBFB40 !important;
}

.b-grid-body-container:focus .b-grid-row.b-selected {
  background-color: #c3c3c340 !important;
}

.b-grid-row.b-selected {
  background-color: #FBFBFB40 !important;
}

.b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell {
  background-color: #FBFBFB !important;
}

.b-gridbase:not(.b-moving-splitter) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell:not(.b-focused) {
  background-color: #FBFBFB !important;
}

.b-gridbase:not(.b-column-resizing):not(.b-row-reordering) .b-grid-header-container:not(.b-dragging-header) .b-depth-0:hover,
.b-gridbase:not(.b-column-resizing):not(.b-row-reordering) .b-grid-header-container:not(.b-dragging-header) .b-depth-0:focus {
  background-color: #f3f4f5;
}

.b-gridbase.b-outer {
  height: 500px;
  border: 1px solid #70707080;
  border-radius: 0px 16px 0px 0px;
}

.b-tooltip-content {
  padding: 0px;
  line-height: normal;
}

.b-grid-header-text>.b-grid-header-text-content {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  text-wrap: wrap;
}

.mphs-text-align {
  text-align: center;
}

.b-sticky-headers .b-sch-header-text.b-sticky-header {
  position: sticky;
  font-size: 14px;
  color: #333333;
}


.b-sch-header-text {

  font-size: 14px;
  color: #333333;
}

.truncate {

  font-size: 14px;
  color: #444444;
}


.truncate .btn-link stretched-link {

  font-size: 14px;
  color: #0645AD;
}

div#b-gantt-1 {
  border-radius: 0px 16px 0px 0px;
}

.b-grid-header-container {
  background-color: #2222221A;

  z-index: 4;
  box-shadow: 0px 2px 6px #00000014;
}

.b-grid-splitter {

  background: #70707080 !important;
  box-shadow: 4px 0px 10px #0000001F !important;

}

#b-combo-1 {
  height: 41.38px;
}

.b-grid-header-container .b-sch-timeaxiscolumn {
  background-color: unset;
}


.b-panel.b-floating {
  border-radius: 0px 20px;
}

.b-hbox.b-box-center.b-panel-body-wrap.b-tooltip-body-wrap {
  border-radius: 0px 20px;
}


.b-grid-row {
  border-bottom: 1px solid #70707080;
}

.b-columnlines .b-grid-cell:not(:last-child) {
  border-right: 1px solid #70707080;
}

.b-grid-header {
  border-inline-end: 1px solid #70707080;
}

.b-horizontaltimeaxis .b-sch-header-timeaxis-cell {
  border-left: 1px solid #70707080;
}

.b-sch-header-timeaxis-cell {
  border-bottom: 1px solid #70707080;
}

.b-column-line,
.b-column-line-major {
  border-color: #70707080;
}