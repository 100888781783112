.feedback-modal .modal-content {
  background: none;
  padding-top: 10px ;
  border: 0px;
  width: 780px;
  margin-left: -25%;
  height: 700px;
}

.feedback-modal .modal-close {
  width: fit-content;
  position: absolute;
  right: 20px; 
  color: #056a7b;
  top: 20px;
  cursor: pointer;
}

.feedback-modal .hide-div {
  display: none;
}

.feedback-modal .modal-loader {
  position: relative;
  margin: 25% auto 25% auto;
  text-align: center;
}

.feedback-modal .modal-loader span svg{
  color: white;
}

.freshwidget-embedded-form {
  border: none;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 16px 0px 16px;
  height: 650px;
}