.audit-dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px auto;
}

.audit-dropdown-item>label {
  line-height: 30px;
  color: #000;
  text-align: left;
}

.audit-dropdown-item.form-check {
  position: relative;
  height: auto;
  width: 100%;
  border-radius: 24px;
}

.audit-dropdown-item>.form-check-input:checked+.form-check-label {
  color: #000;
}

.audit-dropdown-item>input {
  display: block !important;
}

.audit-dropdown {
  display: block;
}

.audit-dropdown>div.dropdown-menu.show {
  transform: translate3d(9px, 64px, 0px) !important;
}

.audit-dropdown-item>.form-check-label {
  width: 100%;
}

.audit-items-div {
  padding: 0px 10px
}