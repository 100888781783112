div.react-draggable h2 {
  font-size: 24px;
  color: #222222DE;
}

h2#draggable-dialog-title {
  font-size: 32px;
  color: #222222DE;
  display: flex;
  height: 92px;
  align-items: center;
  padding: 16px 24px;
}

div.react-draggable h2>div {
  margin-left: auto;
}

.MuiDialog-paperScrollPaper {
  border-radius: 0px 16px !important;
}

div.react-draggable h3 {
  font-size: 20px;
  font-weight: bold;
  color: #222222DE;
}

div.react-draggable h4 {
  font-size: 16px;
  font-weight: bold;
  color: #222222DE;
}

div.react-draggable a {
  color: #0645AD;
  font-size: 14px;
}

div.react-draggable p {
  color: #222222;
  font-size: 14px;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
  width: 0px;
  border-right: 23px white solid;
  border-radius: 0px;
}

.MuiDialogContent-root::-webkit-scrollbar {
  width: 32px;
}

.MuiDialogContent-root::-webkit-scrollbar-track {
  width: 10px;
  background: transparent;
}