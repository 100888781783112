.studyDetails-commentsHeader {
    padding-bottom: 32px;
}

.comments-colWidth5Percent {
    width: 5%;
}

.comments-colWidth7Dot5Percent {
    width: 7.5%;
}

.comments-colWidth10Percent {
    width: 10%;
}

table.MuiTable-root {
    width: inherit;
}

.comments-tableHeaderRow {
    background: #2222221A;
    color: #333333;
    text-transform: uppercase;
    font-size: 14px;
}


.comments-tableHeaderCell {
    background: #2222221A;
    border-radius: 3px 3px 0px 0px;
    color: #333333;
    text-transform: uppercase;
    font-size: 14px;
}

td.MuiTableCell-root.comments-tableHeaderCell {
    font-size: 14px;
    color: #333333;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Bold';
}

.comments-tableBody {
    background: #FCFCFC;
}

.comments-tableRowCell {
    color: #444444;
    font-size: 14px;
}

div.MuiInputBase-root.MuiInputBase-colorPrimary {
    margin-left: 0px;
    border-left: 0px;

}

.MuiIconButton-root.Mui-disabled {
    color: #CCCCCC;
}


.DraftEditor-root {
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    padding: 20px 10px;
}


div#model-select,
li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.comments-menuItem,
li.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.comments-menuItem {
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    font-size: 13px;
    color: #888888;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    width: 50px;
}

li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.MuiTablePagination-menuItem,
li.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.MuiTablePagination-menuItem {
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
    font-size: 14px;
    color: '#222222',
}

.MuiTablePagination-displayedRows {
    padding-top: 5px;
}


.css-nnbavb {
    display: none;
}


th.MuiTableCell-root.commentTable-notesCell {
    border-right: 0px;
}

th.MuiTableCell-root.commentTable-actionCell {
    border-left: 0px;
}

td.MuiTableCell-root.commentTable-notesCell {
    border-right: 0px;
}

td.MuiTableCell-root.commentTable-actionCell {
    border-left: 0px;
}


td.MuiTableCell-root.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.commentTable-notesCell.comments-tableRowCell {
    max-width: 300px;
}

.makeStyles-commentP-10 {
    margin-bottom: 0px;
}


p.jss9 {
    margin-bottom: 0px;
}

input.MuiSelect-nativeInput.MuiSelect-nativeInput {
    border: 0px;
}

div.MuiTablePagination-select.MuiSelect-select.MuiSelect-standard.MuiInputBase-input {
    border: 1px solid #D6D6D6;
    border-left: 0px;
    border-radius: 0px 4px 4px 0px;
    background: #ffffff;
    color: #555555;
    margin-top: -10px;
    font-size: 0.875rem;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 22px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
}

p.MuiTablePagination-selectLabel {
    margin-bottom: 0px;
    border: 1px solid #D6D6D6;
    border-radius: 4px 0px 0px 4px;
    background: #F6F6F6;
    color: #333333;
    margin-top: -10px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
    /* eslint-disable-next-line */
    font-family: 'AvenirNextLTPro-Regular';
}

td.MuiTableCell-root.MuiTableCell-sizeMedium.MuiTablePagination-root.comments-customTablePagination {
    padding-bottom: 10px;
    border-bottom: 0px;
}


.comments-tableWrapper {
    border: 1px solid rgb(112, 112, 112, 0.5);
    border-radius: 0px 20px 0px 20px;
    margin-left: 12px;
    margin-right: 12px;
}

@supports (-moz-appearance:none) {
    .comments-tableWrapper .commentTable-notesCell td {
        background-clip: padding-box;
    }
}

.label-external {
    left: 24px;
}

.label-internal {
    left: 75px;
}

.switch-internal {
    display: none;
}

.comment-filter {
    float: left;
    margin-right: 20px;
}

span.switch-selection.switch-external {
    height: 38px;
}

table.slate-table td {
    border: 1px solid lightgray !important;
}