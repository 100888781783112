.logout-modal .modal-dialog {
  max-width: 25% !important;
}

.terms-modal .modal-dialog {
  max-width: 80% !important;
}

.terms-modal .modal-dialog .modal-body.content-body {
  height: 40vw;
  overflow-y: scroll;
}

.terms-modal .modal-dialog .modal-body div[title="footer"] {
  display: none;
}

.terms-ContainerParent {
  margin-top: 16px;
}

.terms-CardHeader {
  border: none;
}

.terms-CardBody {
  margin: 0px 32px 32px 12px;
}

.terms-PageTitle {
  font-size: 32px;
  color: #056A7B;
}

.terms-FormBody {
  padding: 32px;
  background: #FBFBFB;
  border: 1px solid #E8E8E8;
  border-radius: 0px 16px;
}

.terms-FormLabel {
  font-size: 16px;
  padding-left: 10px;
}

.terms-ModalLink {
  font-size: 16px;
  color: #056A7B;
  text-decoration: underline;
  padding-left: 5px;
}

button.terms-SubmitButton.btn.btn-primary {
  font-size: 16px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
  letter-spacing: 0.64px;
  background: var(--orange);
  border: 1px solid var(--orange);
  border-radius: 4px;
  text-transform: uppercase;
  opacity: unset;
  margin-right: 20px;
  margin-top: 32px;
}

button.terms-SubmitButton:not(:disabled):not(.disabled).active,
button.terms-SubmitButton:not(:disabled):not(.disabled).active:focus,
button.terms-SubmitButton:not(:disabled):not(.disabled):active,
button.terms-SubmitButton:not(:disabled):not(.disabled):active:focus,
.show>button.terms-SubmitButton.dropdown-toggle,
.show>button.terms-SubmitButton.dropdown-toggle:focus,
button.terms-SubmitButton.btn.btn-primary:hover,
button.terms-SubmitButton:active,
button.terms-SubmitButton:focus,
button.terms-SubmitButton:visited {
  color: #222222;
  background: var(--orange);
}
button.terms-SubmitButton.btn.btn-primary:disabled{
  color: #ffffff;
  background: #989898;
  border: none
}

.terms-BackButton {
  color: #222222;
  font-size: 16px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
  letter-spacing: 0.64px;
  background: #FFFFFF;
  border: 1px solid var(--orange);
  border-radius: 4px;
  text-transform: uppercase;
  margin-top: 32px;
}

button.terms-BackButton:hover,
button.terms-BackButton:active,
button.terms-BackButton:focus,
button.terms-BackButton:visited {
  color: #222222;
  background: #F9A868;
  border: 1px solid var(--orange);
  box-shadow: none;
}

button.terms-BackButton:not(:disabled):not(.disabled).active,
button.terms-BackButton:not(:disabled):not(.disabled).active:focus,
button.terms-BackButton:not(:disabled):not(.disabled):active,
button.terms-BackButton:not(:disabled):not(.disabled):active:focus,
.show>button.terms-BackButton.dropdown-toggle,
.show>button.terms-BackButton.dropdown-toggle:focus {
  color: #222222;
  background: #F9A868;
  border: 1px solid var(--orange);
  box-shadow: none;
}

.rememberDeviceTC {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 32px;
}

.rememberDeviceTop {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 0px;
}

.rememberDevice label {
  margin-bottom: 0;
}

p.MuiTypography-root.TermsCheckboxLabel {
  font-size: 16px;
  /* eslint-disable-next-line */
  font-family: 'AvenirNextLTPro-Medium';
  color: #222222;
}

label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.terms-formsControlLabel {
  margin-left: 0px;
}

.accountSettings-cancelChanges {
  margin-top: 0px;
  margin-bottom: 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid var(--orange);
  border-radius: 4px;
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
  width: auto;
}

.accountSettings-cancelChanges:hover {
  color: #222222;
  background: var(--orange);
  border: 1px solid var(--orange);
}

.accountSettings-cancelChanges:focus {
  color: #222222;
  background: var(--orange);
  border: 1px solid var(--orange);
}

.accountSettings-cancelChanges:active {
  color: #222222;
  background: var(--orange);
  border: 1px solid var(--orange);
}

.accountSettings-saveChanges {
  margin-top: 0px;
  margin-bottom: 0px;
  background: var(--orange) 0% 0% no-repeat padding-box;
  border: 1px solid var(--orange);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 16px;
  text-transform: uppercase;
  width: auto;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
}
.profileSettings-saveChanges {
  margin-top: 0px;
  margin-bottom: 0px;
  background: var(--orange) 0% 0% no-repeat padding-box;
  border: 1px solid var(--orange);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 16px;
  text-transform: uppercase;
  width: auto;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
}


.accountSettings-saveChanges:hover {
  color: #222222;
  background: var(--orange);
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
  border: 1px solid var(--orange);
}

.accountSettings-saveChanges:focus {
  color: #222222;
  background: var(--orange);
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
  border: 1px solid var(--orange);
}

.accountSettings-saveChanges:active {
  color: #222222;
  background: var(--orange);
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
  border: 1px solid var(--orange);
}

.accountSettings-saveChanges.disabled, .accountSettings-saveChanges:disabled {
  color: #222222;
  background: #FFFFFF;
  border: 1px solid var(--orange);
}

.editIcon {
  margin-top: -5px;
  margin-left: 5px;
  cursor: pointer;
}
.AccountPreferences-SuccessMessage {
  display: flex;
  /* eslint-disable-next-line */
  font-family: 'AvenirNextLTPro-Regular';
  margin-top: 12px;
  margin-bottom: 12px;
  background: #E9FFF3;
  vertical-align: middle;
  padding: 10px;
}

.AccountPreferences-ErrorMessage {
  display: flex;
  /* eslint-disable-next-line */
  font-family: 'AvenirNextLTPro-Regular';
  margin-top: 12px;
  margin-bottom: 12px;
  background: #FFF5F3;
  vertical-align: middle;
  padding: 10px;
}
