
.reference-line-container {
  position: absolute;
  bottom: 2%;
  left: 2.5%;
  display: flex;
  align-items: center;
}

.reference-line-container .reference-line-label {
  font-family: "AvenirNextLTPro-Medium", sans-serif;
  font-weight: bold;
  font-size: 16px !important;
  margin-right: 30px;
  margin-left: -35%;
  margin-bottom: -15%;
}

.reference-line-container .maxcut-line-label {
  font-family: "AvenirNextLTPro-Medium", sans-serif;
  font-weight: bold;
  font-size: 16px !important;
  margin-right: 30px;
  margin-top: 4%;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
}

.input-container .label {
  font-family: "AvenirNextLTPro-Medium", sans-serif;
  font-weight: bold;
}

.checkbox-apply-container .on-off-label {
  font-family: "AvenirNextLTPro-Medium", sans-serif;
  font-weight: bold;
  margin-bottom: 28px;
}

.input-container .reference-line-input {
  border: 2px solid #F58232;
  border-radius: 3px;
  padding: 3px;
  width: 80px;
  height: 25px;
  margin-bottom: 2%;
}

.input-container .maxcut-line-input {
  border: 2px solid #F58232;
  border-radius: 3px;
  padding: 3px;
  width: 80px;
  height: 25px;
  margin-bottom: -40%;
}

.checkbox-apply-container {
  display: flex;
  align-items: center;
}

.checkbox-apply-container .checkbox-container {
  margin-top: 45%;
  margin-right: 30px;
  margin-left: -33px;
}

.checkbox-apply-container .maxcut-checkbox-container {
  margin-bottom: -130%;
  margin-right: 30px;
  margin-left: -43px;
}

/* Adjust the checkbox size and color */
.checkbox-container input[type="checkbox"] {
  transform: scale(2);
}

.checkbox-apply-container .maxcut-checkbox-container input[type="checkbox"] {
  transform: scale(2);
}

.summary-chart .apply-button {
  position: absolute;
  background-color: #F58232;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px !important;
  text-align: center;
  font-weight: bold;
  height: 25px;
  width: 150px;
  margin-left: 13.5%;
  margin-top: 1.5%;
}

.disabled-input {
  color: #555;
  background-color: #ccc;
}
