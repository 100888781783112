/* GLOBAL COLOR VALUES */
:root {
  --teal: #056A7B;
  --dark-teal: #002334;
  --yellow: #FFD200;
  --orange: #f58232;
  --red: #B9283F;
  --dark: #222222;
  --light: #ffffff;
  --light-gray: #f6f6f6;
}

body {
  overflow: scroll !important;
}

.AvenirNextLTProDemi {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Demi";
}

.AvenirNextLTProMedium {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Medium";
}

.AvenirNextLTProRegular {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
}

.AvenirNextLTProBold {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Bold";
}

.AvenirNextLTProItalic {
  /* eslint-disable-next-line */
  font-family: "AvenirNextVariable-Italic";
}

.padding-left-60 {
  padding-left: 60px !important;
}

.disabled {
  color: gray;
  pointer-events: none;
}

.progress-bar-title {
  position: absolute;
  text-align: center;
  line-height: 15px;
  overflow: hidden;
  color: black;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;

}

.cncl-bttn {
  padding: .2rem;
  width: 50px;
}

.progress {
  position: relative;
}

.drop-zone-info-text {
  font-size: xx-small;
  font-weight: bold;
  margin-top: 5px;
}

.drop-zone-progress-bar .progress-bar {
  color: #000000 !important;
  font-size: xx-small !important;
  font-weight: bold !important;
}

.height-10 {
  height: '10px' !important;
}

.height-40 {
  height: '40px' !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-l-20 {
  margin-left: 20px !important;
}

.padding-l-10 {
  padding-left: 10px !important;
}

.padding-l-20 {
  padding-left: 20px !important;
}

.margin-r-20 {
  margin-right: 20px !important;
}

.margin-r-40 {
  margin-right: 40px !important;
}

.ellipse-onwrap {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.font-size-15 {
  font-size: 15px !important
}

.width-auto {
  width: auto !important;
  white-space: nowrap !important;
}

.width-150 {
  width: 150px !important;
  white-space: nowrap !important;
}

.width-165 {
  width: 165px !important;
}

.width-125 {
  width: 125px !important;
}

.width-50 {
  width: 50px !important;
}

.table {
  table-layout: fixed !important;
}

.buttonWithTransparentBackground {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

.buttonWithTransparentBackground:hover {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

button:active {
  outline: none;
  border: none;
}

button:focus {
  outline: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none !important;
}

.uploader-dimensions {
  width: "100%" !important;
  height: "100px" !important;
}

.nav-link.help-content {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  cursor: pointer;
}

.ag-cell-label-container-rotated {
  height: 100px;
  margin-top: -17px;
  padding-bottom: 8px;
  padding-top: 1px;
  margin-bottom: -1px;
  overflow: visible;
}

.ag-header-cell-label-rotated {
  height: 100%;
  padding: 0 !important;
}

.ag-header-cell-comp-wrapper {
  display: flex;
  justify-content: center;
}


.padding-lr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-l2 {
  padding-left: 2 !important;
}

.padding-l4 {
  padding-left: 4px !important;
}

.padding-l0 {
  padding-left: 0 !important;
}

.padding-r0 {
  padding-right: 0px !important;
}

.padding-5 {
  padding: 5 !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.padding-t-b-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.ag-header-cell {
  justify-content: center;
}

.ag-header-cell-label-padding-0,
.ag-header-cell-text-padding-0 {
  padding: 0 !important
}

.ag-header-cell-label-rotated .ag-header-cell-text-rotated {
  width: 100%;
  transform: rotate(-90deg) !important;
  display: inline-block;
  position: absolute;
  bottom: 6px;
  font-size: 14px;
  -webkit-text-size-adjust: 120%;
  text-transform: uppercase;
  white-space: nowrap;
  left: 110px;
  transform-origin: bottom left;
  text-align: left;
  line-height: 1.5;
  height: 100%;
}

.ag-header-cell-text {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  text-transform: uppercase;
  padding: 20px, 24px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Bold";
}

.ag-header-row-floating-filter .ag-header-cell.ag-focus-managed {
  padding-left: 10px;
  padding-right: 2px;
}


.padding-0 {
  padding: 0 !important;
}

.padding-l3 {
  padding-left: 3px !important;
}

.padding-l-24 {
  padding-left: 24px !important;
}

.padding-vtxt-10 {
  padding-left: 10px !important;
}

.margin-top-42 {
  margin-top: 42px !;
}

.text-align-left {
  text-align: left !important;
}

.inline-block {
  display: inline-block !important;
}

.tumor-volume-default-header {
  text-align: center;
}

.ag-header {
  height: 90px;
}

.tumor-volume-default-cell {
  text-align: center;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.tumor-volume-group-cell {
  text-align: left !important;
}

.tumor-volume-study-day-metrics-header .ag-header-cell-text {
  white-space: pre-wrap;
  text-align: center;
}

.padding-b2 {
  padding-bottom: 2px !important;
}

.text-align-center {
  text-align: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.ag-header-group-cell-label,
.ag-header-cell-label {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 2px;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pills-padding {
  padding-top: 0.0rem !important;
  padding-right: 0.5rem !important;
  padding-bottom: 0.0rem !important;
  padding-left: 0.5rem !important;
}

.card-header-pills {
  margin-left: 20px !important;
}

.rem-25-padding {
  padding-top: 0.25rem !important;
  padding-right: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.rem-065-padding {
  padding-top: 0.65rem !important;
  padding-right: 0.65rem !important;
  padding-bottom: 0.65rem !important;
  padding-left: 0.65rem !important;
}

.max-width-50 {
  max-width: 50px;
}

.display-none {
  display: none !important;
}

.display-flex {
  display: flex;
}

.visibility-hidden {
  visibility: hidden;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-14 {
  font-size: 14px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
  color: #444444;
}

.padding-2 {
  padding: 2px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-item {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.blackHyperlink {
  color: black;
  text-decoration: none;
}

.blackHyperlink:hover {
  color: black;
  text-decoration: none;
}

.lightGrayHyperlink {
  color: lightgray;
  text-decoration: none;
}

.lightGrayHyperlink:hover {
  color: lightgray;
  text-decoration: none;
}

.font-size-16 {
  font-size: 16px !important;
}

.fill-yellow {
  fill: yellow;
}

.PhoneInputInput {
  border: 1px solid lightgrey;
  border-radius: 4px;
  height: 56px;
  outline: none;
}

#main-header-notifications:after {
  display: none !important;
}

.dropdown-menu {
  max-height: 400px;
  padding: 0px;
}

.App.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.toolbar {
  display: flex;
  height: 80px;
  line-height: 60px;
  background-color: #056a7b;
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.logo-img {
  height: 56px;
}

.logo-title {
  font-size: 28px;
  margin-left: 24px;
  text-align: left;
  font-weight: bold;
  letter-spacing: 1.12px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  position: absolute;
  top: 10px;
}

.nav-link {
  border-radius: .99rem !important;
  padding-top: 0.0rem !important;
  padding-right: 0.5rem !important;
  padding-bottom: 0.0rem !important;
  padding-left: 0.5rem !important;
  margin-left: 12px;
  text-align: center;
  line-height: inherit;
}

.nav-link:hover {
  background-color: #09768a;
  color: #ffffff;
}

.nav-link .usr-pro {
  height: 80px;
}

.App-logo {
  pointer-events: none;
  text-transform: uppercase;
  font: normal normal bold 28px/38px "Avenir Next";
  color: #ffffff;
  opacity: 1;
}

.App-logo img {
  margin-right: 24px;
}

.spacer {
  flex-grow: 1;
}

.icon {
  width: 32px;
  height: 32px;
  margin-right: 32px;
}


.user-profile-outer {
  width: auto;
  height: 48px;
  margin: auto;
}

.user-name {
  padding-right: 12px;
  width: auto;
  height: 21px;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-transform: capitalize;
}

.user-profile {
  padding-right: 20px;
}

.drop-down {
  padding: 32px, 12px, 32px, 0px;
}

.menu-item:hover {
  background: #fff6d9 !important;
}

.badge-col {
  box-sizing: border-box;
  background-color: #eeb200;
  position: relative;
  top: -10px;
  left: -12px;
  color: #000;
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 50%;
}

.badge-txt {
  position: relative;
  top: 0;
}

.logout {
  width: 32px;
  height: 30px;
  opacity: 1;
}

.title-header img {
  margin-top: "300px";
}

.d-flex {
  display: flex;
}

.text_container {
  margin-top: 34px;
  margin-bottom: 33px;
}

.bc-margin-bl {
  font-size: 14px;
  padding-bottom: 27px;
}

.icon-txt {
  color: #056A7B;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 32px;
}

.icon-txt-italic {
  color: #056A7B;
  text-align: left;
  font-style: italic;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 32px;
}

.footer {
  background-color: #002334;
  margin-top: auto;
}

.footer-links {
  display: grid;
  grid-template-columns: 13% 13% 13% auto;
  padding: 0px 0px 0px 64px;
}

a.footer-link {
  color: #ffffff;
  opacity: 1;
  font-size: 22px;
  font-weight: normal;
  line-height: 32px;
  cursor: pointer;
  text-decoration: inherit;
}


.grid-footer {
  padding-left: 64px;
}

.footer .copyrights {
  line-height: 19px;
  text-align: right;
  color: #D3DBE2;
  font-weight: normal;
  opacity: 1;
  padding: 20px 64px 20px 0;
  font-size: 14px;
}

.copyrights-img {
  text-align: right;
  padding-right: 64px;
  padding-top: 124px;
}


.help-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-300) var(--unnamed-font-size-32) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-222222);
  text-align: left;
  letter-spacing: 0px;
  color: #222222de;
  opacity: 1;
}

.close-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--orange) 0% 0% no-repeat padding-box;
  color: var(--orange);
  opacity: 1;
}

.bullet {
  box-sizing: border-box;
  background: var(--blue-link-—-064599) 0% 0% no-repeat padding-box;
  background: #064555 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2px 9px;
  border-radius: 50%;
}

.popper-footer {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.popper-list-footer {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) / 32px var(--unnamed-font-family-avenir-next);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--blue-link-—-064533);
  text-align: left;
  opacity: 1;
}

.searchIcon {
  padding: 10;
}

.input {
  flex: 1;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  color: "#424242";
}

.popper {
  width: 448px;
  max-height: 664px;
  box-shadow: 0px 4px 10px #00000033;
  border-radius: 6px;
  background: #ffffff;
  z-index: 99;
  position: fixed;
  right: 80px;
  top: 6.5em;
}

.notifications {
  border-radius: 6px 6px 0px 0px;
  background: #ffe96b 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
}

.noti_text {
  font-size: 18px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: bold;
}

.noti_icons {
  width: 24px;
  height: 24px;
  margin-left: 32px;
}

.mark_all_icon {
  height: 22px;
}

.notes {
  display: flex;
  padding: 8px 0;
}

.notes:first-child {
  padding-top: 2px;
}

.notes_icon {
  display: flex;
  margin-right: 12px;
  padding-top: 5px;
}

.notes_message {
  letter-spacing: 0px;
  color: #333333;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.notes_update {
  letter-spacing: 0px;
  color: #666666;
  font-size: 11px;
}

.landing-icon {
  margin-right: 12px;
}

.data-grid {
  display: flex;
  flex-flow: column;
  position: relative;
  margin-bottom: 110px;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #FAFAFA 0% 0% no-repeat padding-box;
}



::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #C3C3C3 0% 0% no-repeat padding-box;
  border: 5px solid rgb(255 255 255 / 0%);
}

.box-outer-padding {
  padding: 40px 0 60px 0;
}


.box-subhead {
  font-size: 16px;
  font-weight: 500;
  color: #ffd200;
  opacity: 1;
  line-height: 22px;
  margin-bottom: 8px;
}

.bcSub {
  letter-spacing: 0;
  color: #056A7B;
  text-align: left;
  font-size: 32px;
  opacity: 1;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
}

.MuiBox-root-2 {
  margin-top: 0;
}

.txt-tab-icon {
  color: #056A7B;
  margin-right: 18px;
}

.txt-tab-header {
  width: 173px;
  height: 33px;
  letter-spacing: 0;
  text-align: left;
  font-size: 24px;
  color: #056A7B;
  opacity: 1;
}

.text-md-left {
  background: #ffffff;

}

.tableIconPad {
  padding: 32px, 32px, 0, 34px;
}


.card-header {
  padding: 32px;
}

.newLine {
  padding-bottom: 34px;
}

.newLineDoc {
  padding-bottom: 40px;
}

.lt-arrow-pad {
  padding-right: 12px;
}

.MuiTypography-root .bc-study-det {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular" !important;
  color: #222222 !important;
  font-size: 14px !important;
  padding-top: 2px;
}

.study-details-breadcrumb-homelink {
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
  font-size: 14px;
  color: #064533;
  text-decoration: none !important;
}

.MuiBreadcrumbs-li.bc-upload-base a {
  color: #007bff;
  font-size: 14px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
}

.card-head1 {
  padding-left: 32px;
  padding-right: 33px;
  border-bottom: none;
  padding-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0px 0px 12px #0000000a;
}

.card-body1 {
  padding-top: 0px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
  padding-bottom: 32px !important;
  margin-bottom: 116px;
  background-color: #fff;
  border-radius: 0px 0px 0px 16px;
}



.study-documents {
  border: 0px !important;
  margin-left: 16px;
}

.study-documents .ag-root-wrapper {
  border: 1px solid rgb(112, 112, 112, 0.5) !important;
}

.ag-root-wrapper {
  border: solid .5px !important;
  border-bottom: solid 1px !important;
  border-color: #DFDFDF !important;
}

.ag-theme-alpine .ag-header-cell {
  border-bottom: solid 0px !important;
  border-right: solid 1px !important;
  border-top: solid 0px !important;
  border-color: rgb(112, 112, 112, 0.5) !important;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  border-top: solid 0px !important;
  border-left: solid 0px !important;
  border-color: rgb(112, 112, 112, 0.5) !important;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular" !important;
  font-size: 14px !important;
  color: #444444;
}


.padding-b-10 {
  padding-bottom: 10px;
}

.btn-link {
  color: #064599;
  font-size: 14px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
}

.ag-theme-alpine .ag-row-odd {
  background-color: #FFF !important;
}

.ag-theme-alpine .ag-row-hover {
  background-color: #FCFCFC !important;
}

.ag-theme-alpine .font-size-1x {
  font-size: 14px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
}
.study-documents ::-webkit-scrollbar {
  height: 0 !important;
  width: 18px;
}

.study-documents ::-webkit-scrollbar-track {
  height: 0 !important;
  width: 0 !important;
}

.study-documents ::-webkit-scrollbar-thumb {
  height: 0 !important;
  width: 0 !important;
}

.study-documents .ag-theme-alpine .ag-root-wrapper {
  border-radius: 0px 16px 0px 16px;
}

.study-documents .card-header:first-child {
  border-radius: 0px 16px 0px 0px;
}

.study-documents .ag-header-container {
  background: #2222221A 0% 0% no-repeat padding-box;
}

.card-body ::-webkit-scrollbar {
  height: 18px;
  width: 18px;
}

.ag-root ::-webkit-scrollbar-track {
  background: transparent;
}

.ag-root ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgb(195, 195, 195, 0.5);
}

.ag-root ::-webkit-scrollbar {
  height: 18px;
  width: 18px;
}

.ag-center-cols-viewport {
  overflow-x: hidden !important;
}


.ag-center-cols-viewport {
  width: 100%;
}

.ag-body-horizontal-scroll-viewport {
  padding-bottom: 5px;
  padding-top: 10px;
  margin-top: -6px;
}

::-webkit-scrollbar-button {
  width: 20px;
  height: 5px;
}

.utxt {
  width: 216px;
  height: 43px;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  font-size: 14px;
  color: #888888 !important;
}

.ul-button {
  position: relative !important;
  padding: '8px 24px' !important;
  background: var(--orange) !important;
  color: #FFF !important;
  width: 236px;
  height: 38px;
}

.ul-button:hover {
  background: #F9A868 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 6px #8576763d !important;
  border-radius: 4px;
  color: #222222 !important;
  opacity: 1;
}

.ag-theme-alpine .ag-icon {
  font-family: "agGridAlpine" !important;
}

.mainblock {
  width: "60%";
  border: "1px solid rgba(0,0,0,.125)";
  border-radius: "4px";
  padding: "32px";
  background: "#ffffff";
  margin-left: "15px";
}

.headTxt {
  font-size: 54;
  color: "#333333";
  font-weight: bold;
}

li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.nav-header-profile-menu-item {
  margin: 12px 0;
  padding-top: 4px;
  padding-bottom: 4px;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0px;
  padding-bottom: 0px;
}

.studyDetContainer {
  width: 100%;
  background-color: #f6f6f6;
  top: 64px;
  padding: 48px;
}

.container-fluid1 {
  padding: 0;
}

svg.upload-icon {
  width: 32px;
  height: 32px;
}

.upload-txt {
  width: 216px;
  height: 43px;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  font-size: 14px;
  color: #888888;
}

.notifications-icon-overlay-counter {
  display: inline;
  background-color: #FFD200;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 0px 4px;
  line-height: 24px;
  font-size: 14px;
  color: #222222;
  padding: 5px 8px;
  margin-left: -12px;
}

.notifications-icon-overlay-counterLarge {
  display: inline;
  background-color: #FFD200;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 0px 4px;
  line-height: 24px;
  font-size: 14px;
  color: #222222;
  padding: 5px 4px;
  margin-left: -12px;
}

a#main-header-notifications {
  margin-bottom: 10px;
}

.landing-navHeader-bellContainer {
  padding-bottom: 15px;
  padding-top: 15px;
}

.landing-navHeader-bellIcon {
  padding-top: 20px;
}

div.dropdown-menu.show {
  box-shadow: 0px 4px 10px #00000033;
  transform: translate(185px, 103px) !important;
  border-radius: 0px 0px 0px 12px;
}

.dropdown-header {
  background: #FFE96B 0% 0% no-repeat padding-box;
  border-radius: 0px 12px 0px 0px;
  height: 48px;
  padding: 12px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -8px;
  margin-bottom: 20px;
}

.landing-navHeader-title {
  margin-top: -12px;
  font-size: 18px;
  color: #333333;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Demi";
}

.landing-navHeader-markAllReadIcon {
  margin-top: -14px;
  margin-right: -185px;
}

.landing-navHeader-manageNotificationsIcon {
  margin-top: -14px;
}

.user-profile1 {
  background-color: #002334;
  text-align: center;
  width: 48px;
  height: 48px;
  border-radius: 0px 8px;
  line-height: 48px;
  font-size: 16px;
  color: #D3DBE2;
  overflow: hidden;
  padding: 12px;
  /* eslint-disable-next-line */
  font-family: 'AvenirNextLTPro-Demi';
}

.landing-userProfile-drpDownContainer {
  width: 40px;
  text-align: center;
}

.landing-userProfile-drpDownContainer:hover {
  background-color: #09768a;
}

.account-settings {
  width: 100%;
  height: 100%;
  min-height: 70vh;
}

.manage-notification-save-button:hover {
  background: #F9A868 !important;
  color: #222222 !important;
}

.manage-notification-weekday-button:hover {
  background: #F9A868 !important;
  color: #222222 !important;
}

.manage-notification-save-button {
  background: var(--orange) 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
  letter-spacing: 0.64px;
  color: #FFFFFF !important;
  height: 38px;
  width: 175px;
}

.manage-notification-save-settings {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  text-align: center;
}

.manage-notification {
  top: 64px;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-left: 64px;
  padding-right: 66px;
  padding-bottom: 544px;
}

.admin-center {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: inherit;
  margin-left: 64px;
  margin-top: 10px;
  font-size: 32px;
}

.manage-notification-inner {
  margin-top: 34px;
  width: 100%;
  border: 1px solid #E8E8E8;
  border-radius: 0px 16px;
  background: #FBFBFB 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 24px 24px 24px 24px;
}

.manage-notification-svg {
  font-size: 32px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px
}

.notifications-eachItem {
  padding: 0 34px 16px 20px;
}

.notifications-eachItem-topLine {
  margin-bottom: 0px;
}

.notification-eachItem-timeString {
  font-size: 11px;
}

a.notifications-eachItem.dropdown-item:hover {
  background-color: #fff;
}

.notifications-body {
  max-height: 300px;
  overflow-y: scroll;
}

.notifications-body::-webkit-scrollbar {
  width: 22px;
}

.notifications-body::-webkit-scrollbar-track {
  background: #fff;
}

.notifications-body::-webkit-scrollbar-thumb {
  background-color: #E8E8E8;
  border-radius: 10px;
  border: 8px solid #fff;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.agGrid-downloadIconColumn {
  cursor: pointer;
  padding-left: 7px !important;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.agGrid-trashIconColumn {
  padding-left: 1px !important;
}

a.studyDocs-link {
  cursor: pointer;
  font-size: 14px;
  color: #064599;
}



.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-top: 2px !important;
  padding-bottom: 0px !important;
}


.padding-tp-24 {
  padding-left: 5px !important;
}

.padding-lt-24 {
  padding-right: 10px !important;
}

.dropdown-toggle::after {
  color: #FFF;
}

.ag-theme-alpine .ag-cell {
  line-height: 60px !important;
}

.ag-cell-wrapper {
  height: unset;
}

.ag-theme-alpine .ag-cell.l-height-10 {
  line-height: 22px !important;
  padding-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-theme-alpine .ag-cell.l-height-10-lf {
  padding-top: 3px;
  display: flex;
  align-items: center;
}

.ag-theme-alpine .ag-cell.l-height-10-2 {
  line-height: 12px !important;
  padding-left: 8px;
}

#sidebar-wrapper {
  min-height: 100vh !important;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  padding-right: 0px !important;
  padding-left: 0px !important;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.landing-navHeader-markAllReadIcon .landing-navHeader-markAllReadIconHover {
  display: none;
  position: absolute;
  z-index: 99;
  top: 22.5px;
  margin-left: -24px;
}

.landing-navHeader-markAllReadIcon:hover .landing-navHeader-markAllReadIconHover {
  display: inline;
  margin-top: -14px;
  margin-right: -185px;
}

.landing-navHeader-manageNotificationsIcon .landing-navHeader-manageNotificationsIconHover {
  display: none;
  position: absolute;
  z-index: 99;
  top: 22.5px;
  margin-left: -24px;
}

.landing-navHeader-manageNotificationsIcon:hover .landing-navHeader-manageNotificationsIconHover {
  display: inline;
  margin-top: -14px;
}

.ag-root-wrapper.ag-layout-normal.ag-ltr {
  border-radius: 0px 16px;
  border: 1px solid #70707080 !important;
}

.ag-theme-alpine .ag-header {
  border-color: #DFDFDF !important;
  border-left: solid 0px !important;
  border-bottom-color: #babfc7;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Demi";
  font-size: 14px;
  color: #333333;
  text-transform: uppercase;
  background-color: #2222221A !important;
}

.userFullName-ContainerParent {
  margin-top: 100px;
}

.userFullName-CardHeader {
  border: none;
}

.userFullName-CardBody {
  margin: 0px 32px 32px 12px;
}

.userFullName-PageTitle {
  font-size: 32px;
  color: #056A7B;
}

.userFullName-FormBody {
  padding: 32px;
  background: #FBFBFB;
  border: 1px solid #E8E8E8;
  border-radius: 0px 16px;
}

.userFullName-FormLabel {
  font-size: 16px;
}

.userFullName-TextBox {
  width: 25%;
  font-size: 16px;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  height: 56px;
}

.userFullName-TextBox:focus {
  border-color: #F8A131;
  box-shadow: none;
}

.userFullName-TextBox:-webkit-autofill,
.userFullName-TextBox:-webkit-autofill:hover,
.userFullName-TextBox:-webkit-autofill:focus,
.userFullName-TextBox:-webkit-autofill:active {
  transition: background-color 1s 5000s;
}

.userFullName-TextBoxPhone {
  width: 25%;
  font-size: 16px;
  border: nonex;
  height: 56px;
}

.userFullName-BottomNotes {
  font-size: 14px;
}

.userFullName-SubmitButton {
  font-size: 16px;
  /* eslint-disable-next-line */
  font-family: "AvenirNextLTPro-Regular";
  letter-spacing: 0.64px;
  background: var(--orange);
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
}

.userFullName-SubmitButton:hover,
.userFullName-SubmitButton:active,
.userFullName-SubmitButton:focus,
.userFullName-SubmitButton:visited {
  color: #222222;
  background: var(--orange);
}

.js-plotly-plot .plotly .modebar {
  top: -12px;
}

.mtb-20 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cust-col-head {
  transform: rotate(-90deg);
  display: flex;
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
  width: 55px;
  position: absolute;
  bottom: 16px;
}


svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.Header-HelpIcon {
  height: 32px;
  width: 32px;
}

.sticky-navbar {
  position: sticky !important;
}

.version {
  display: block;
}

.icp-china-url {
  margin-inline-end: 10px;
}

.center-grid-cell-data {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.transform-0-15 {
  transform: translate(0%, -16%);
}

.b-grid-header .b-sch-timerange.b-sch-line {
  background-color: var(--orange) !important;
  height: 20%;
  padding-top: 10px;
}

.b-timeline-subgrid .b-sch-current-time {
  border-left-color: var(--orange);
}

.MuiSnackbarContent-root {
  background-color: unset !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.MuiSnackbarContent-message {
  padding: 12px 0 22px 0 !important;
}

.Mng-not-msg-txt {
  padding: 5px !important;
}

select option:disabled {
  color: lightgrey;
}

.wrap-header .ag-header-cell-text {
  white-space: normal;
}

.centered-cell-content .ag-cell-wrapper {
  justify-content: center;
}

.ag-floating-filter-button {
  visibility: hidden;
  margin-left: 4px !important;
}

.ag-text-field-input {
  background-color: white !important;
}

.w-300 {
  width: 300px;
}

#custom-tooltip {
  position: absolute;
  background: #ffffcc;
  border: 1px solid black;
  padding: 5px;
  z-index: 9999;
  color: black;
  width: 300px;
}

.logo-box {
  cursor: pointer;
}

.bgLightgray {
  background-color: lightgray;
}

.file-list-grp {
  overflow-y: scroll;
  max-height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.file-name {
  display: flex;
  justify-content: space-between;
  color: #FFC455;
  margin-top: 5px;
  margin-bottom: 5px;
}

.file-upload-alert {
  margin-left: 10px;
  margin-right: 5px;
}

.file-s3-obj-path::before {
  content: "\2014\00A0";
}