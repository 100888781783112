.card-header-pills-2.mt-2.nav.card-header-pills-2.nav-pills.study-list {
  background-color: #F0F5FC;
  margin-top: 40px;
  border-radius: 4px;
  box-shadow: 0px 0px 12px #0000000a;
}
.card-header-pills.study-list {
  margin-left: 20px !important;
}

.nav-link.study-list-pill {
  margin-right: 12px;
}

.study-list-nav-pills-band {
  margin-top: 0px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tab-content-study-list {
  width: fit-content;
}

.study-list-nav-pills-band-disabled {
  background-color: lightgrey !important;
  cursor: not-allowed !important;
}
.study-list-nav-pills-band-disabled > .nav-item {
  cursor: not-allowed !important;
}

a.badge.nav-link.study-list-pill.nav-link.active {
  background: #0645AD !important;
  color: #ffffff;
  border-radius: 24px !important;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  font-family: "AvenirNextLTPro-Demi", sans-serif;
}

a.badge.nav-link.study-list-pill.nav-link {
  color: #0645AD;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  font-family: "AvenirNextLTPro-Demi", sans-serif;
}

a.badge.nav-link.study-list-pill.nav-link:hover {
  background-color: rgba(255, 255, 255, 0);
}
