@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }

    .custom {
        display: block;
        page-break-before: auto;
    }

}