.studyDocumentsContainer {
  margin-bottom: 1rem;
  border-radius: 0px 16px;
  background-color: white;
  overflow: hidden;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  box-shadow: none;
  background-color: white;
  padding-top: 2rem;
  padding-bottom: 0;
  .cardHeaderTitle {
    display: flex;
    column-gap: 0.6rem !important;
    font-size: 1.5rem !important;
    font-weight: 400 !important;
    color: #056a7b;
    margin-bottom: 0.5rem !important;
  }
  .cardHeaderSubtitle {
    font-size: 0.8rem;
    font-weight: 400;
    color: #6c757d !important;

  }
  .cardHeaderButton {
    background-color: white;
    color: var(--dark) !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    padding: 0.5rem 1rem !important;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: 1px solid var(--orange) !important;
    border-radius: 0.3rem;
  }
  .cardHeaderButton:hover {
    background-color: var(--orange) !important;
  }
}

.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  .breadcrumbsItem {
    text-transform: none !important;
    display: flex;
    column-gap: 0.4rem;
  }
}

.disabled {
  color: #1976d2 !important;
}

.tableLoader {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputField {
  background-color: white;
  width: 100%;
  margin: 0;
  border-radius: 5px;
  overflow: hidden; 
  .inputFieldLabel {
    font-size: 0.8rem;
    font-weight: 600;
    color: #6c757d;
    margin-bottom: 0.5rem;
  }
  .inputFieldInput {
    border: 1px solid #ced4da;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-weight: 400;
    color: #495057;
    width: 100%;
    transition: all 0.2s ease-in-out;
    &:focus {
      border-color: #056a7b;
      box-shadow: 0 0 0 0.2rem rgba(5, 106, 123, 0.25);
    }
  }
}

.noDataText {
  font-size: 1rem;
  font-weight: 400;
  color: #6c757d;
  margin: 1rem 0;
  text-align: center;
}

