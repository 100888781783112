.color-panel {
  border-radius: 0px 16px;
}

.color-panel .title {
  background: #F9F9F9 0% 0% no-repeat padding-box;
}

.color-panel .action-button, .color-chooser-action-button {
  border: 3px solid #e68946;
  color: black;
  height: 30px;
  width: 120px;
  font-weight: bold;
}

.color-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 464px;
  border: 1px solid #002334;
  background-color: #fff;
}

.color-modal .color-modal-title {
  font-size: 17px;
  font-weight: 500;
  padding: 10px;
  background-color: #eee;
}

.color-modal .color-modal-footer {
  padding: 10px;
  background: #eee;
}

.color-modal .color-modal-content {
  padding: 10px
}

.color-modal .color-modal-content .inputs {
  clear: both;
}

.color-modal .color-modal-content .inputs .alpha {
  position: relative;
  inset: 0px;
  width: 100%;
}

.color-modal .color-modal-content .input {
  display: flex;
}

.color-modal .color-modal-content .label {
  margin-right: 10px;
}

.color-panel .tabs button {
  border: 2px solid #e68946;
  color: black;
  height: 35px;
  width: 32%;
  font-weight: bold;
}

.color-panel .tabs button.Mui-selected {
  background-color: #e68946;
  color: white;
  text-decoration: none !important;
}

.color-panel .palette {
  width: 20px;
  height: 20px;
  border: 1px solid;
  float: left;
  margin-top: 11px;
}

.color-panel .default-colors label {
  float: left;
}

.color-panel .custom-colors label {
  float: left;
}

.color-panel .dots {
  padding-top: 13px;
  width: fit-content;
  float: left;
}

.color-panel .custom-colors .action-dropdown {
  float: right;
}

.color-panel .custom-colors .menu {
  width: fit-content;
  float: right;
  margin-top: 47px;
  margin-right: -26px;
  position: absolute;
  right: 60px;
  background: white;
  z-index: 1000;
}

.color-panel .custom-colors .menu-list {
  list-style: none;
  padding: 0px;
  border: 1px solid #eee;
}

.color-panel .custom-colors .menu-item {
  min-width: 130px;
}

.color-panel .custom-colors .menu-item .menu-button {
  text-align: left;
  width: 100%;
  justify-content: flex-start;
}

.color-panel .groups-list {
  list-style: none;
}

.color-panel .groups-list .group-item {
  padding: 10px;
  display: flex;
}

.color-panel .groups-list .group-item .label{
  width: 26%;
}

.color-panel .CcTitle {
  font-family: "AvenirNextLTPro-Medium", sans-serif;
  padding-left: 16px;
  font-size: 28px !important;
  font-weight: 900;
  color: #056A7B !important;
}

.color-panel .form-control {
  border: 0px solid #ced4da;
  padding: 0px;
}

.color-panel .css-1aquho2-MuiTabs-indicator {
  background-color:#ffffff
}

.color-panel .actionButtonDialog{
  margin-bottom: 35px;
}

.color-panel .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-height: 10px;
}

.color-panel .help-text{
  margin-top: -2%;
  font-family: "AvenirNextLTPro-Medium", sans-serif;
  color: black;
}
