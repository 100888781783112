@import '~@bryntum/demo-resources/scss/example.scss';

.b-tree-icon,
.b-tree-expander {
    display: none;
}

.b-tree-cell-inner {
    padding-left: 0 !important;
}

.b-react-portal-container {
    width: 100%;
}

.b-gantt-task-wrap:not(.b-milestone-wrap) .b-gantt-task:hover,
.b-gantt-task-hover,
.b-gantt-task.b-task-selected,
.b-gantt-task:hover {
    background: #A5E5F4;
}

.b-gantt-task.b-milestone label,
.b-gantt-task .b-gantt-task-content,
.b-gantt-task-wrap:not(.b-milestone-wrap) .b-gantt-task {
    font-size: 30px;
}


.b-task-rollup-wrap {
    margin-top: -30px;
    width: fit-content;
}

.b-task-rollup.b-milestone {
    height: .55em;
    width: .55em;
    z-index: 50;
}

.b-gantt-task-parent .b-gantt-task {
    max-height: 1.15em;
    margin-top: -6px;
}

.b-gantt-task,
.b-gantt-task-parent:not(.b-milestone-wrap) .b-gantt-task,
.b-gantt-task.b-task-selected,
.b-gantt-task-hover,
.b-gantt-task:hover {
    background-color: #C1F4FF;
}

.b-gantt-task.b-sch-event-resizable-false.b-milestone {
    visibility: hidden;
}

.truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 25;
}

.siteFilter {
    min-width: fit-content;
    overflow-x: visible;
}

.black {
    background-color: #002334 !important;
    z-index: 49 !important;
}

.green {
    background-color: #23A314 !important;
}

.centerText {
    text-align: left;
}

.milestoneTooltipEntry {
    width: auto;
    height: auto;
    padding: 0px;
    margin: 0px;
    background: #1b1010 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 12px #00000033;
    border-radius: 20px !important;
    opacity: 1;
    white-space: nowrap;
}

.b-hbox {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 12px #00000033;
}

.med> {
    font-size: 14px;
}

.med>b {
    /* eslint-disable-next-line */
    font-family: "AvenirNextLTPro-Demi";
}


.reg {
    font-size: 14px;
}

.customTooltip {
    padding: 0px;
    margin: 0px;
    box-shadow: 50px #00000033;
    border-radius: 20px !important;
    opacity: 1;
}

.tooltipHeader {
    padding: 0px;
}

.left-l {
    max-width: 200px;
    float: left;
    font-weight: bold;
    font-size: 14px;
    /* eslint-disable-next-line */
    font: 'Avenir Next LT Pro';
    background-color: #FFF3EA;
}

.right-r {
    max-width: 341px;
    float: right;
    font-size: 14px;
    background-color: #fff;
}

.miles-left-l,
.miles-right-r {
    display: inline-block;
}

.miles-left-l {
    float: left;
    font-weight: bold;
    font-size: 14px;
    background-color: #FFF3EA;
    padding: 13px;
    color: #444444;
}

.miles-right-r {
    float: right;
    font-size: 14px;
    background-color: #fff;
    padding: 13px;
    color: #222222;
}

.marginBelow-t {
    padding: 0 0 8px 0;
}

.marginBelow-m {
    padding: 8px 0 8px 0;
}

.marginBelow-b {
    padding: 8px 0 0 0;
}

.l {
    padding: 16px;
    font-size: 14px;
    color: #444444;
}

.r {
    padding: 16px;
    font-size: 14px;
    color: #222222;
}

.noteDis {
    border: 1px solid #70707080;
    padding: 8px 0px 8px 25px;
    /* eslint-disable-next-line */
    font-family: "AvenirNextLTPro-Regular";
    font-size: 14px;
    color: #666666;
}

.rnd-bdr {
    border-radius: 0px 0px 0px 16px;
}