.studySummaryContainer {
  background-color: white;
  border-radius: 0 16px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.studySummaryInfoContainer {
  position: relative;
  .studySummaryModalContainer {
    position: absolute;
    margin-top: 10px;
    z-index: 200 !important;
    width: 700px;
    max-width: 700px;
    background-color: white;
    border: 2px solid #056a7b;
    border-radius: 0 16px;
    box-shadow: 10px 10px 22px #0000000a;
    overflow: hidden;
    padding: 0 1rem;
    .studySummaryModal {
      padding: 1.5rem;
      .studySummaryModalHeader {
        display: flex;
        margin-bottom: 1rem;
      }
      .studySummaryModalSubHeader {
        font-size: 10px;
      }
      .studySummaryModalBody {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
      }
    }
    .studySummaryModalCloseButton {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 1.5rem;
      color: #056a7b;
    }
  }
}


.cardHeader {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  box-shadow: none;
  padding-top: 2rem;
  padding-bottom: 0;
  .cardHeaderTitle {
    display: flex;
    column-gap: 0.6rem !important;
    font-size: 1.5rem !important;
    font-weight: 400 !important;
    color: #056a7b;
    margin-bottom: 0.5rem !important;
  }
  .cardHeaderSubtitle {
    font-size: 0.8rem;
    font-weight: 400;
    color: #6c757d !important;
  }
}
.cardBody {
  background-color: white;
}

.noDataText {
  font-size: 1rem;
  font-weight: 400;
  color: #6c757d;
  margin: 1rem 0;
  text-align: center;
}
.noDataTextLeft {
  font-size: 1rem;
  font-weight: 400;
  color: #6c757d;
  margin: 1rem 0;
}
